import { useEffect, useState } from "react";

import { ThirdfortBlock } from "../TransactionBlocks/ThirdfortBlock";
import { AdminUser } from "../../pages/UserDetails/types";
import { Giftors } from "../../pages/TransactionDetails/types";
import { useTransactionContext } from "../../context/TransactionProvider";

interface PurchaseIntentUser {
  buyers: AdminUser[];
  giftors: Giftors[];
}

export interface ThirdfortDetail {
  label: string;
  value: string | undefined;
}

export const ThirdfortTab = () => {
  const { transaction } = useTransactionContext();

  const [purchaseIntentUsers, setPurchaseIntentUsers] = useState<
    PurchaseIntentUser[]
  >([]);

  useEffect(() => {
    if (!transaction) return;
    const transformPurchaseIntentUsers = transaction.purchase_intent.map(
      (i) => {
        const buyers: AdminUser[] = [];
        const giftors: Giftors[] = [];
        i.buyers.forEach((seller) => {
          buyers.push(seller.user);
          giftors.push(...(seller.giftors || []));
        });
        return { buyers, giftors };
      },
    );
    setPurchaseIntentUsers(transformPurchaseIntentUsers);
  }, [transaction]);

  return (
    transaction && (
      <>
        {transaction.sellers.map((seller, index) => {
          return (
            <ThirdfortBlock
              key={`thirdfort-seller-${index}`}
              title={`Seller ${index + 1}`}
              address={transaction.property_id.address}
              profile={seller.user}
              solicitorEmail={
                transaction.lawyer_group?.seller.solicitor.email.toLowerCase() ||
                ""
              }
              leftLabel={`Seller ${index + 1} of ${transaction.sellers.length}`}
            />
          );
        })}
        {purchaseIntentUsers.map(({ buyers, giftors }, purchaseIntentIndex) => {
          return (
            <>
              {buyers.map((buyer, index) => {
                return (
                  <ThirdfortBlock
                    key={`thirdfort-buyer-${purchaseIntentIndex}-${index}`}
                    title={`Buyer ${index + 1}`}
                    address={transaction.property_id.address}
                    profile={buyer}
                    solicitorEmail={
                      transaction.lawyer_group?.buyer?.solicitor.email.toLowerCase() ||
                      ""
                    }
                    leftLabel={`Purchase Intent ${purchaseIntentIndex + 1} of ${purchaseIntentUsers.length} | Buyer ${index + 1} of ${buyers.length}`}
                    userType="Buyer"
                  />
                );
              })}
              {giftors.map((giftor, index) => {
                return (
                  <ThirdfortBlock
                    key={`thirdfort-giftor-${purchaseIntentIndex}-${index}`}
                    title={`Giftor ${index + 1}`}
                    address={transaction.property_id.address}
                    profile={giftor}
                    solicitorEmail={
                      transaction.lawyer_group?.buyer?.solicitor.email.toLowerCase() ||
                      ""
                    }
                    leftLabel={`Purchase Intent ${purchaseIntentIndex + 1} of ${purchaseIntentUsers.length} | Giftor ${index + 1} of ${giftors.length}`}
                    userType="Buyer"
                  />
                );
              })}
            </>
          );
        })}
      </>
    )
  );
};
