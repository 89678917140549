import { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import { Authenticate, LinkExpired } from "./pages/Authenticate";
import { BuyerRouter } from "./routes/BuyerRouter";
import { CookiePolicy } from "./marketing/pages/CookiePolicy";
import { FullPageLoader } from "./pages/FullPageLoader/FullPageLoader";
import { GetStarted } from "./pages/GetStarted/GetStarted";
import { PageNotFound } from "./pages/PageNotFound/PageNotFound";
import { PartnerSignUp } from "./pages/PartnerSignUp/PartnerSignUp";
import { PrivacyPolicy } from "./marketing/pages/PrivacyPolicy";
import { ProtectedRoute } from "./components/ProtectedRoute/ProtectedRoute";
import { SellerLandingPage } from "./marketing/";
import { SellerRouter } from "./routes/SellerRouter";
import { SignIn } from "./pages/SignIn/SignIn";
import { useAuthStore } from "./store/auth/auth";
import { useLoginStore } from "./store/Login/login";
import { WelcomeBack } from "./pages/WelcomeBack/WelcomeBack";

import { AdminRouter } from "./admin/routes/AdminRouter";
import { SENTRY_APP_ENV } from "./constants";

const MainRoute = () => {
  const { role } = useLoginStore();
  return role === "buy" ? <BuyerRouter /> : <SellerRouter />;
};

const AppRoutes = () => {
  const { isInitialized } = useAuthStore();
  const { role } = useLoginStore();
  const location = useLocation();

  useEffect(() => {
    if (SENTRY_APP_ENV === "development") {
      console.log("Customer signing in with role:", role);
    }
  }, [role, location]);

  useEffect(() => {
    // Scroll to top on route change
    window.scrollTo(0, 0);
  }, [location.pathname]);

  if (!isInitialized) {
    return <FullPageLoader />;
  }

  return (
    <Routes>
      {/* Sign-in Routes */}
      <Route path="/sign-in" element={<SignIn />} />

      {/* Authentication Routes */}
      <Route path="/authenticate" element={<Authenticate />} />
      <Route path="/authenticate/link-expired" element={<LinkExpired />} />

      {/* Transaction Switcher Routes */}
      <Route
        path="/welcome-back"
        element={
          <ProtectedRoute>
            <WelcomeBack />
          </ProtectedRoute>
        }
      />

      {/* Admin Routes */}
      <Route path="/admin/:page/edit/:id" element={<AdminRouter />} />
      <Route path="/admin/:page/:id" element={<AdminRouter />} />
      <Route path="/admin/:page" element={<AdminRouter />} />
      <Route path="/admin" element={<AdminRouter />} />

      {/* Onboarding Flow Routes */}
      <Route path="/:role/get-started" element={<GetStarted />} />
      <Route path="/:role/:page" element={<MainRoute />} />

      {/* Marketing Website Routes */}
      <Route path="/" element={<SellerLandingPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/cookie-policy" element={<CookiePolicy />} />
      <Route path="/partner-sign-up" element={<PartnerSignUp />} />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export const App = () => (
  <BrowserRouter>
    <AppRoutes />
  </BrowserRouter>
);
