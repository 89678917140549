import { useNavigate } from "react-router-dom";
import { useState } from "react";

import Typography from "@/foundation/Typography/Typography";
import ErrorText from "@/foundation/Typography/ErrorText";
import { Button } from "@/components/Button/Button";

import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";
import { useEstateAgentContext } from "../../context/EstateAgentProvider";
import { AdminTitle } from "../AdminPageTemplate/AdminTitle";
import { EstateAgentBlock } from "./EstateAgentBlock";
import { EstateAgentValuersBlock } from "./EstateAgentValuersBlock";
import { EstateAgentOfficeBlock } from "./EstateAgentOfficeBlock";
import { EstateAgentNegotiatorsBlock } from "./EstateAgentNegotiatorsBlock";

interface EstateAgentProps {
  title?: string;
}

export const EstateAgent = ({ title = "Estate Agent" }: EstateAgentProps) => {
  const navigate = useNavigate();

  const { loading, error, estateAgent, createEstateAgent } =
    useEstateAgentContext();

  const [errorMessage, setErrorMessage] = useState("");

  const onCreateEstateAgent = async () => {
    const { error, id } = await createEstateAgent!();

    if (error) {
      setErrorMessage(error);
    } else {
      navigate(`/admin/estate-agents/${id}`);
    }
  };

  return (
    <>
      <AdminTitle title={title} />
      {loading && <FullPageLoader />}
      {error && (
        <Typography variant="lg" weight="bold" type="p">
          {error}
        </Typography>
      )}
      <div className="flex flex-col space-y-5 pb-[24px]">
        {estateAgent?._id && <EstateAgentBlock />}
        <EstateAgentValuersBlock />
        <EstateAgentOfficeBlock />
        <EstateAgentNegotiatorsBlock />
        {!estateAgent?._id && (
          <div className="flex flex-col items-end">
            {errorMessage && (
              <div className="mb-[12px]">
                <ErrorText>{errorMessage}</ErrorText>
              </div>
            )}
            <Button variant="primary" onClick={onCreateEstateAgent}>
              Submit
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
