import { useEffect, useState } from "react";

import { Card } from "@/components/Card/Card";
import { Table, TableProps } from "../Table/Table";
import { useEstateAgentContext } from "../../context/EstateAgentProvider";
import {
  EstateAgentOfficeModal,
  newInitialValues,
} from "../Modals/EstateAgentOfficeModal";
import { EstateAgent } from "../../../types";
import { Button } from "@/components/Button/Button";

export const EstateAgentOfficeBlock = () => {
  const { estateAgent, updateEstateAgent, officeFieldsEmpty } =
    useEstateAgentContext();
  const [fields, setFields] = useState<TableProps[]>([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const office = estateAgent?.office;

    if (office) {
      const officeFields = [
        { label: "Brand", value: estateAgent?.brand },
        {
          label: "Name",
          value: office?.name,
        },
        {
          label: "Phone Number",
          value: office?.phone_number,
        },
        { label: "Address Line 1", value: office?.address?.line_1 },
        { label: "Address Line 2", value: office?.address?.line_2 },
        { label: "Address Line 3", value: office?.address?.line_3 },
        { label: "Post Town", value: office?.address?.post_town },
        { label: "Country", value: office?.address?.country },
        { label: "Postcode", value: office?.address?.postcode },
      ];

      setFields(officeFields);
    }
  }, [estateAgent]);

  const onSumbit = async (values: Partial<EstateAgent>) => {
    const { error } = await updateEstateAgent!({ ...estateAgent!, ...values });

    if (error) {
      setErrorMessage(error);
    } else {
      setIsModalOpen(false);
    }
  };

  return (
    <>
      <Card
        title="Office"
        titleRightContent={
          officeFieldsEmpty ? undefined : (
            <button
              className="text-brand-heavy-warmth-25 underline"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Edit
            </button>
          )
        }
      >
        <>
          {officeFieldsEmpty ? (
            <div className="flex justify-center">
              <Button variant="secondary" onClick={() => setIsModalOpen(true)}>
                Add office details
              </Button>
            </div>
          ) : (
            fields.map(({ value, label, includeCopyButton }) => (
              <div key={label} className="flex jusify-top">
                <Table
                  key={value as string}
                  label={label}
                  value={value}
                  includeCopyButton={includeCopyButton}
                />
              </div>
            ))
          )}
          {isModalOpen && (
            <EstateAgentOfficeModal
              initialValues={
                estateAgent
                  ? {
                      ...estateAgent,
                      office: {
                        ...estateAgent.office,
                        address:
                          estateAgent.office.address ??
                          newInitialValues.office.address,
                      },
                    }
                  : newInitialValues
              }
              errorMessage={errorMessage}
              onClose={() => setIsModalOpen(false)}
              onSubmit={onSumbit}
            />
          )}
        </>
      </Card>
    </>
  );
};
