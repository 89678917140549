import { AdminTitle } from "../../components/AdminPageTemplate/AdminTitle";

export const TransactionsAdd = () => (
  <>
    <div className="flex">
      <AdminTitle title="Add a New Transaction" />
    </div>

    <div>Add a new Transaction here</div>
  </>
);
