import { useMemo, useRef, memo, useState, useEffect } from "react";
import { Field, FieldProps, Formik } from "formik";
import { withZodSchema } from "formik-validator-zod";
import { z } from "zod";

import { Modal } from "../../../components/Modal/Modal";
import { Input } from "../../../components/Formik/Input/Input";
import { Select } from "@/components/Input/Select";
import { AddressInputs } from "../../../components/Form/DetailsForm/AddressLookup/AddressInputs";
import { useEstateAgentContext } from "../../context/EstateAgentProvider";
import ErrorText from "@/foundation/Typography/ErrorText";
import { findAllEstateAgentBrands } from "../../service/AdminApiController/findAllEstateAgentBrands";

export const officeForm = z.object({
  brand: z.string().min(1, { message: "Brand is required" }),
  office: z.object({
    name: z.string().min(1),
    phone_number: z.string().optional(),
    address: z
      .object({
        line_1: z.string(),
        line_2: z.string().optional(),
        line_3: z.string().optional(),
        post_town: z.string(),
        country: z.string(),
        postcode: z.string(),
      })
      .optional(),
  }),
});

export const newInitialValues = {
  brand: "",
  office: {
    name: "",
    address: {
      line_1: "",
      post_town: "",
      country: "",
      postcode: "",
    },
  },
};

interface EstateAgentOfficeModalProps {
  initialValues?: z.infer<typeof officeForm>;
  errorMessage?: string;
  onSubmit: (values: z.infer<typeof officeForm>) => void;
  onClose: () => void;
}

export const EstateAgentOfficeModalComponent = ({
  initialValues = newInitialValues,
  errorMessage,
  onSubmit,
  onClose,
}: EstateAgentOfficeModalProps) => {
  const { loading } = useEstateAgentContext();

  const [brands, setBrands] = useState<string[]>([]);

  const initialValuesRef = useRef(initialValues);

  const renderError = useMemo(
    () => <ErrorText>{errorMessage}</ErrorText>,
    [errorMessage],
  );

  useEffect(() => {
    async function fetchBrands() {
      try {
        const brandsRes = await findAllEstateAgentBrands();
        setBrands(brandsRes.map((b) => b.brandName));
      } catch {
        setBrands([]);
      }
    }

    fetchBrands();
  }, []);

  return (
    <Formik
      initialValues={initialValuesRef.current}
      validate={withZodSchema(officeForm)}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, errors, touched }) => (
        <Modal
          title="Office"
          saveButtonFunction={handleSubmit}
          isSaveButtonDisabled={loading}
          cancelButtonFunction={() => onClose()}
        >
          <>
            <div className="mb-[25px]">
              <Field name="brand">
                {({ field }: FieldProps) => {
                  return (
                    <Select
                      {...field}
                      options={brands.map((brand) => ({
                        value: brand,
                        label: brand,
                      }))}
                      name="brand"
                      id="brand"
                      variant={
                        errors.brand && touched.brand ? "error" : "enabled"
                      }
                    />
                  );
                }}
              </Field>
            </div>
            <div className="mb-[8px] md:max-w-[320px]">
              <Input fieldName="office.name" label="Office Name" />
            </div>
            <div className="mb-[8px] md:max-w-[320px]">
              <Input
                fieldName="office.phone_number"
                label="Office Phone Number (optional)"
              />
            </div>
            <AddressInputs
              namespace="office.address"
              addressTitle="Estate agent address"
              addressDescription={
                "Please enter the correspondence address for the estate agent (optional)"
              }
              cardAddressTitle="Estate agent address"
            />
            {renderError}
          </>
        </Modal>
      )}
    </Formik>
  );
};

export const EstateAgentOfficeModal = memo(EstateAgentOfficeModalComponent);
