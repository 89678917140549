import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

import { Table, TableRow } from "@/components/Table/Table";
import { Button } from "@/components/Button/Button";
import { ApiController } from "../../../service/ApiController";
import Typography from "@/foundation/Typography/Typography";
import { AdminTransaction } from "../TransactionDetails/types";
import { AdminTitle } from "../../components/AdminPageTemplate/AdminTitle";
import { formatCurrency, getAddressAsString } from "@shared/utils";
import {
  getFirstTwoFullnames,
  getLatestPurchaseIntent,
} from "../../../utils/utils";
import Icons from "@/foundation/Icons/Icons";
import {
  TransactionsFilterModal,
  paidStatusFilter,
  PaidStatusFilter,
} from "../../components/TransactionsFilterModal/TransactionsFilterModal";
import TextInput from "@/components/Input/TextInput";
import { AdminConfig } from "../../types/types";
import { getTimeOfferToExchangeInDays } from "./helpers";
import { InvoicingBlock } from "../../components/Invoicing/InvoicingBlock";

interface TransactionRow {
  sellerFullNames: string;
  propertyAddress: string;
  askingPrice: string;
  latestBuyerFullNames: string;
  sellerSolicitor: string;
  buyerSolicitor: string;
  dateLegalsStarted: string;
}

const formatDate = (date: string | Date | undefined) => {
  if (!date) return undefined;
  return format(new Date(date), "dd/MM/yyyy");
};

export const Transactions = () => {
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState<AdminTransaction[]>([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [config, setConfig] = useState<AdminConfig>();

  const [paidStatusFilterOption, setPaidStatusFilterOption] =
    useState<PaidStatusFilter>(undefined);

  const [displayedTransactions, setDisplayedTransactions] =
    useState<TableRow<TransactionRow>[]>();

  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [invoicingModalOpen, setInvoicingModalOpen] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");

  useEffect(() => {
    async function getAllTransactionsAndAdminConfig() {
      setLoading(true);
      setError("");

      try {
        const configRes = await ApiController.findAdminConfig();
        const existingTransactions = await ApiController.findAllTransactions({
          isPaid: paidStatusFilterOption,
          searchValue: debouncedSearchValue,
        });

        setConfig(configRes);
        setTransactions(existingTransactions);
        setLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          setError("There was an error while fetching transactions");
          setLoading(false);
        }
      }
    }
    getAllTransactionsAndAdminConfig();
  }, [setTransactions, paidStatusFilterOption, debouncedSearchValue]);

  useEffect(() => {
    const getDataRows = (
      transactions: AdminTransaction[],
    ): TableRow<TransactionRow>[] => {
      return transactions.map((transaction) => {
        const {
          sellers,
          property_id: { address },
          date_solicitor_groups_assigned,
        } = transaction;

        const latestPurchaseIntent = getLatestPurchaseIntent(
          transaction.purchase_intent,
        );

        const timeOfferToExchangeInDays = getTimeOfferToExchangeInDays(
          latestPurchaseIntent?.significant_dates,
        );

        const timeSaved =
          timeOfferToExchangeInDays && config?.benchmarkInDays
            ? config.benchmarkInDays - timeOfferToExchangeInDays
            : undefined;

        const percentageTimeSaved =
          timeSaved && config?.benchmarkInDays
            ? Math.round((timeSaved / config.benchmarkInDays) * 100)
            : "TBC";

        return {
          data: {
            sellerFullNames: getFirstTwoFullnames(sellers),
            propertyAddress: getAddressAsString(address, true),
            askingPrice: formatCurrency(transaction.asking_price, true, true),
            latestBuyerFullNames: getFirstTwoFullnames(
              latestPurchaseIntent?.buyers || [],
            ),
            sellerSolicitor: transaction.lawyer_group?.seller.solicitor
              ? `${transaction.lawyer_group?.seller.solicitor?.first_name} ${transaction.lawyer_group?.seller.solicitor?.last_name}`
              : "",
            buyerSolicitor: transaction.lawyer_group?.buyer
              ? `${transaction.lawyer_group?.buyer.solicitor?.first_name} ${transaction.lawyer_group?.buyer.solicitor?.last_name}`
              : "",
            dateLegalsStarted:
              formatDate(date_solicitor_groups_assigned?.date_legal_started) ||
              "Not started",
            dateSaleAgreed: formatDate(
              latestPurchaseIntent?.significant_dates?.sale_agreed,
            ),
            dateExchanged: formatDate(
              latestPurchaseIntent?.significant_dates?.exchanged,
            ),
            dateCompleted: formatDate(
              latestPurchaseIntent?.significant_dates?.completed,
            ),
            timeOfferToExchange: timeOfferToExchangeInDays
              ? timeOfferToExchangeInDays.toString()
              : "TBC",
            timeSaved: timeSaved ? timeSaved.toString() : "TBC",
            percentageTimeSaved,
            tags: (transaction.tags || []).join(", "),
          },
          onClick: () => navigate(`/admin/transactions/${transaction._id}`),
        };
      });
    };

    setDisplayedTransactions(getDataRows(transactions));
  }, [setDisplayedTransactions, transactions, navigate, config]);

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [searchValue]);

  const columns = [
    { key: "sellerFullNames", title: "Seller Full Name(s)" },
    { key: "propertyAddress", title: "Property Address" },
    { key: "askingPrice", title: "Asking Price" },
    { key: "sellerSolicitor", title: "Seller Solicitor" },
    { key: "buyerSolicitor", title: "Buyer Solicitor" },
    { key: "latestBuyerFullNames", title: "Latest Buyer Full Name(s)" },
    { key: "dateLegalsStarted", title: "Date Legals Started" },
    { key: "dateSaleAgreed", title: "Date Sale Agreed" },
    { key: "dateExchanged", title: "Date Exchanged" },
    { key: "dateCompleted", title: "Date Completed" },
    { key: "timeOfferToExchange", title: "Time Offer To Exchange In Days" },
    { key: "timeSaved", title: "Time Saved In Days" },
    { key: "percentageTimeSaved", title: "% Time Saved" },
    { key: "tags", title: "Tags" },
  ];

  return (
    <>
      <div className="flex">
        <AdminTitle title="Transactions" />

        <Button
          className="mt-[20px] md:mt-[40px]"
          variant="primary"
          onClick={() => navigate("/admin/transactions/add")}
        >
          Add transaction
        </Button>
      </div>

      {error && (
        <Typography variant="lg" weight="bold" type="p">
          {error}
        </Typography>
      )}

      {!error && (
        <div>
          <div className="flex flex-col md:flex-row justify-between mb-[12px]">
            {/* Left Section: Export CSV Button */}
            <div className="md:w-auto w-full">
              <Button
                type="button"
                onClick={() => setInvoicingModalOpen(!invoicingModalOpen)}
                variant="primary"
                size="2xl"
                className="mb-[16px] md:mb-0 md:order-1"
              >
                Export CSV
              </Button>
            </div>

            {/* Right Section: Search Bar and Filter */}
            <div className="flex flex-col md:flex-row-reverse items-center md:justify-end md:space-x-[16px] md:space-x-reverse md:w-auto w-full mt-[12px] md:mt-0">
              {/* Search Bar */}
              <div className="md:w-auto w-full">
                <TextInput
                  showIcon={true}
                  variant="enabled"
                  label="Search"
                  name="search"
                  icon="Search"
                  value={searchValue}
                  onChange={(val) => setSearchValue(val.target.value)}
                  className="w-full md:w-[320px]"
                />
              </div>

              {/* Filter */}
              <div className="relative flex flex-row-reverse md:flex-row items-center justify-end md:w-auto w-full mt-[12px] md:mt-0">
                {paidStatusFilterOption !== undefined && (
                  <div className="flex items-center bg-brand-heavy-teal-100 px-[16px] py-[4px] rounded-[100px] text-ui-white-100 mx-[16px]">
                    <Typography>
                      {paidStatusFilterOption ? "Paid" : "Unpaid"}
                    </Typography>
                    <button
                      className="ml-[8px]"
                      onClick={() =>
                        setPaidStatusFilterOption(paidStatusFilter.all)
                      }
                    >
                      <Icons iconName="Close" colour="complement" />
                    </button>
                  </div>
                )}

                <button
                  className="flex items-center justify-center border h-[64px] w-[64px] rounded-lg text-brand-heavy-teal-100 border-brand-heavy-teal-100 hover:text-brand-heavy-teal-75"
                  onClick={() => setFilterModalOpen(true)}
                >
                  <Icons iconName="Filter" size="24" />
                </button>

                {filterModalOpen && (
                  <div className="top-[68px] absolute">
                    <TransactionsFilterModal
                      paidStatusFilterOption={paidStatusFilterOption}
                      setPaidStatusFilterOption={setPaidStatusFilterOption}
                      onCloseModal={() => setFilterModalOpen(false)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <Table columns={columns} rows={displayedTransactions} />
        </div>
      )}

      {invoicingModalOpen && (
        <InvoicingBlock
          onCloseModal={() => setInvoicingModalOpen(false)}
          transactions={transactions}
          invoicingAmount={config?.invoicingAmount}
        />
      )}

      {loading && (
        <Typography variant="lg" weight="bold" type="p">
          Loading...
        </Typography>
      )}
    </>
  );
};
