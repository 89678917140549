import * as Sentry from "@sentry/react";

import { api } from ".";

export const validateReferralCode = async (code: string): Promise<boolean> => {
  try {
    const response = await api.post("/referral-code/validate", { code });
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Error validating referral code");
  }
};
